// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';
import { generatePath } from 'react-router';
import { api } from 'jsw';
import pageData from '../helpers/pageData';

const withPageData = ({ dataPath, component, ...props }) => ({
    ...props,
    component: async ({ url, params }) => {
        const { isInitial, ...data } = pageData();

        const [newData, module] = await Promise.all([
            dataPath === false ? {} : isInitial ? data : api.get((dataPath ? generatePath(dataPath, params) : url) + window.location.search),
            component(),
        ]);

        if (newData.redirect) {
            return newData;
        }

        pageData(newData);
        const Component = module.default || module;
        return (
            <Component {...newData} />
        );
    },
});

export default [
    {
        path: '/cp',
        dataPath: false,
        component: () => import(/* webpackMode: "eager" */'./components/NotFound'),
    },
    {
        path: ['/login_up.php', '/login_up.php3', '/login'],
        dataPath: '/login_up.php',
        component: () => import(/* webpackMode: "eager" */'./components/Login'),
    },
    {
        path: '/get_password.php',
        dataPath: '/get_password.php',
        component: () => import(/* webpackMode: "eager" */'./components/GetPassword'),
    },
    {
        path: '/ch_pass_by_secret.php',
        dataPath: '/ch_pass_by_secret.php',
        component: () => import(/* webpackMode: "eager" */'./components/RestorePassword'),
    },
    {
        path: '/admin/setup/progress',
        component: () => import(/* webpackChunkName: "views/admin/setup/progress" */'./views/admin/setup/progress'),
    },
    {
        path: '/admin/setup',
        component: () => import(/* webpackChunkName: "views/admin/setup/index" */'./views/admin/setup/index'),
    },
    {
        path: '/admin/home',
        component: () => import(/* webpackChunkName: "views/admin/home/admin" */'./views/admin/home/admin'),
    },
    {
        path: '/admin/about',
        component: () => import(/* webpackChunkName: "views/admin/about/index" */'./views/admin/about/index'),
    },
    {
        path: ['/admin/domain/list', '/admin/domain/add-domain'],
        dataPath: '/admin/domain/list',
        component: () => import(/* webpackChunkName: "views/admin/domain/list" */'./views/admin/domain/list'),
    },
    {
        path: ['/admin/customer/list', '/admin/customer/create'],
        dataPath: '/admin/customer/list',
        component: () => import(/* webpackChunkName: "views/admin/customer/list" */'./views/admin/customer/list'),
    },
    {
        path: '/admin/customer/overview/id/:id',
        component: () => null,
    },
    {
        path: ['/admin/customer/domains/id/:id', '/admin/customer/add-domain/id/:id'],
        dataPath: '/admin/customer/domains/id/:id',
        component: () => import(/* webpackChunkName: "views/admin/customer/domains" */'./views/admin/customer/domains'),
    },
    {
        path: ['/admin/customer/subscriptions/id/:id', '/admin/customer/new-subscription/id/:id'],
        dataPath: '/admin/customer/subscriptions/id/:id',
        component: () => import(/* webpackChunkName: "views/admin/customer/subscriptions" */'./views/admin/customer/subscriptions'),
    },
    {
        path: '/admin/reseller/list',
        component: () => import(/* webpackChunkName: "views/admin/reseller/list" */'./views/admin/reseller/list'),
    },
    {
        path: '/admin/reseller/overview/id/:id',
        component: () => null,
    },
    {
        path: ['/admin/reseller/customers/id/:id', '/admin/reseller/new-customer/id/:id'],
        dataPath: '/admin/reseller/customers/id/:id',
        component: () => import(/* webpackChunkName: "views/admin/reseller/customers" */'./views/admin/reseller/customers'),
    },
    {
        path: ['/admin/reseller/domains/id/:id', '/admin/reseller/add-domain/id/:id'],
        dataPath: '/admin/reseller/domains/id/:id',
        component: () => import(/* webpackChunkName: "views/admin/reseller/domains" */'./views/admin/reseller/domains'),
    },
    {
        path: ['/admin/reseller/subscriptions/id/:id', '/admin/reseller/new-subscription/id/:id'],
        dataPath: '/admin/reseller/subscriptions/id/:id',
        component: () => import(/* webpackChunkName: "views/admin/reseller/subscriptions" */'./views/admin/reseller/subscriptions'),
    },
    {
        path: '/admin/server/tools',
        component: () => import(/* webpackChunkName: "views/admin/server/tools" */'./views/admin/server/tools'),
    },
    {
        path: '/admin/sessions/list',
        component: () => import(/* webpackChunkName: "views/admin/sessions/list" */'./views/admin/sessions/list'),
    },
    {
        path: ['/admin/subscription/list', '/admin/subscription/create'],
        dataPath: '/admin/subscription/list',
        component: () => import(/* webpackChunkName: "views/admin/subscription/list" */'./views/admin/subscription/list'),
    },
    {
        path: '/admin/subscription/login/id/:id/',
        component: () => null,
    },
    {
        path: '/admin/subscription/overview/id/:id/',
        component: () => null,
    },
    {
        path: ['/admin/help/tutorials', '/smb/help/tutorials'],
        component: () => import(/* webpackChunkName: "views/default/help/tutorials" */'./views/default/help/tutorials'),
    },
    {
        path: ['/smb/file-manager/list', '/smb/file-manager/list/domainId/:domainId'],
        exact: true,
        component: () => import(/* webpackChunkName: "views/smb/file-manager/list" */'./views/smb/file-manager/list'),
    },
    {
        path: '/smb/file-manager',
        exact: true,
        component: () => import(/* webpackChunkName: "views/smb/file-manager/index" */'./views/smb/file-manager/index'),
    },
    {
        path: ['/smb/web/setup', '/smb/web/add-domain/create-webspace/true'],
        dataPath: '/smb/web/setup',
        component: () => import(/* webpackChunkName: "views/smb/web/setup" */'./views/smb/web/setup'),
    },
    {
        path: ['/smb/web/view', '/smb/web/add-domain'],
        dataPath: '/smb/web/view',
        component: () => import(/* webpackChunkName: "views/smb/web/view" */'./views/smb/web/view'),
    },
    {
        path: '/smb/web/overview/id/:id/type/:type',
        component: () => import(/* webpackChunkName: "views/smb/web/view" */'./views/smb/web/view'),
    },
    {
        path: '/smb/statistics/details',
        component: () => import(/* webpackChunkName: "views/smb/statistics/details" */'./views/smb/statistics/details'),
    },
    {
        path: '/smb/statistics/list',
        component: () => import(/* webpackChunkName: "views/smb/statistics/details" */'./views/smb/statistics/list'),
    },
    {
        path: '/smb/statistics',
        exact: true,
        component: () => null,
    },
    {
        path: ['/smb/account/switch/all/:all/id/:id', '/smb/account/switch/id/:id'],
        component: () => null,
    },
    {
        path: '/smb/database',
        exact: true,
        component: () => import(/* webpackChunkName: "views/smb/database/index" */'./views/smb/database/index'),
    },
    {
        path: ['/smb/database/list', '/smb/database/list/domainId/:domainId'],
        exact: true,
        component: () => import(/* webpackChunkName: "views/smb/database/list" */'./views/smb/database/list'),
    },
    {
        path: '/smb/database/users-list/domainId/:domainId',
        exact: true,
        component: () => import(/* webpackChunkName: "views/smb/database/users-list" */'./views/smb/database/users-list'),
    },
    {
        path: '/admin/task-manager',
        component: () => import(/* webpackChunkName: "views/admin/task-manager/index" */'./views/admin/task-manager/index'),
    },
    {
        path: ['/smb/dns-zone/records-list/id/:id/type/:type(domain|alias)'],
        component: () => import(/* webpackChunkName: "views/smb/dns-zone/records-list" */'./views/smb/dns-zone/records-list'),
    },
].map(withPageData);
